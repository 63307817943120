import React from "react";
import TwoColGrid from "../common/TwoColGrid";

type serviceType = {
  id: number;
  firstSection: React.ReactNode;
  secondSection: React.ReactNode;
  isReversed?: boolean;
  className?: string;
};

type ServicesType = {
  services: serviceType[];
};

const Services: React.FC<ServicesType> = ({ services }) => {
  return (
    <div>
      <h2 className="text-center text-dsc-title text-[24px] leading-[28.13px] font-bold mb-12">
        サービス内容
      </h2>
      <div className="[&_h3]:text-dsc-title [&_h3]:text-[20px] [&_h3]:leading-[22px] [&_h3]:mb-4 [&_h3]:font-bold [&_h3]:lg:mt-6 [&_p]:text-dsc-body [&_p]:text-base [&_p]:leading-[22px]">
        {services.map((service) => (
          <TwoColGrid
            firstSection={service.firstSection}
            secondSection={service.secondSection}
            isReversed={service.isReversed || false}
            className={service.className || ""}
            key={service.id}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
