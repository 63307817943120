import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ArchiveTitle from "../components/common/ArchiveTitle";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Button from "../components/common/Button";
import Divider from "../components/common/Divider";
import Layout from "../components/common/Layout";
import Meta from "../components/common/Meta";
import TwoColGrid from "../components/common/TwoColGrid";
import BestSellingProducts from "../components/home/BestSellingProducts";
import Services from "../components/services/Services";

const Service = ({ data }) => {
  const meta = {
    title: "サービス｜（株）Digital Stacks",
    description:
      "Digital Stacksは、技術的な実装からコンサルティング、デジタルマーケティングまで、ビジネスのスケーラブル化を支援するサービスを提供しています。",
    pathname: "/services/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <Breadcrumbs path="/services" className="mt-4 mb-8" />
      <div className="px-4 lg:px-[100px]">
        <ArchiveTitle
          title="Digital Stacksのサービス"
          shortText="技術的な導入からコンサルティング、デジタルマーケティングまで、お客様のビジネスをスケーラブルにするためにサポートいたします。"
        />
        <TwoColGrid
          firstSection={serviceIntro.firstSection}
          secondSection={serviceIntro.secondSection}
        />
        <Divider spacing={72} />
        <Services services={servicesData} />
        <BestSellingProducts
          products={data.bestSellingProducts.nodes}
          breakpoint={500}
        />
        <Divider spacing={40} />
        <div className="flex justify-center items-center mb-[72px] [&_a]:text-sm [&_a]:font-bold [&_a]:leading-4">
          <Button
            title="製品詳細"
            size="normal"
            path="/products/"
            className="hover:bg-dsc-white hover:border hover:border-dsc-button hover:text-dsc-button"
          />
        </div>
      </div>
    </Layout>
  );
};

const serviceIntro = {
  firstSection: (
    <StaticImage
      src="../../static/images/services/image1.png"
      alt="Services Image 1"
      objectFit="cover"
      width={470}
      quality={100}
    />
  ),
  secondSection: (
    <div>
      <h2 className="mb-6 dsc-h3 font-bold leading-[30px] text-dsc-title">
        ビジネスに最適なITサポート、ソフトウェア導入、トレーニング。
      </h2>
      <p className="text-dsc-body text-base leading-6">
        株式会社Digital
        Stacksは、インターネット業界で既に25年以上の経験を持ち、ITテクノロジーコンサルティング会社としての実績を積んできました。
        <br />
        PagerDuty、Harness、Digital.ai、Smartsheetなど、あらゆる業界の企業に必要な新しいソフトウェアソリューションや最新のプロジェクト管理ツールを導入し、お客様のビジネスの成長、エンゲージメントの促進、生産性の向上などに貢献したいと考えています。企業がテクノロジーソリューションをどのように導入するかを計画し、エンドユーザーにその使い方をトレーニングするITコンサルティングサービスも行っています。デジタルマーケティングをはじめ、技術的な実装やコンサルティングなど、幅広いニーズに対応することで、数々のお客様から大きな信頼をいただいています。
      </p>
    </div>
  ),
};

const servicesData = [
  {
    id: 1,
    firstSection: (
      <StaticImage
        quality={100}
        src="../../static/images/services/image2.png"
        alt="Services Image 2"
        objectFit="cover"
        width={470}
      />
    ),
    secondSection: (
      <div>
        <h3>海外製品の技術サポート</h3>
        <p className="leading-[22px]">
          弊社では、海外製品に関する情報や技術サポートなどを、全て日本語で対応しています。また、日本語によるテクニカルサポートも無料で提供しています。
        </p>
      </div>
    ),
    isReversed: true,
    className: "mb-14",
  },
  {
    id: 2,
    firstSection: (
      <StaticImage
        quality={100}
        src="../../static/images/services/image3.png"
        alt="Services Image 3"
        objectFit="cover"
        width={470}
      />
    ),
    secondSection: (
      <div>
        <h3>ビジネスにおけるインサイトを得る</h3>
        <p className="leading-[22px]">
          ITコンサルティング、カスタマーサポート、新しいソフトウェアソリューションの導入に関するトレーニングなど、幅広いサービスを提供しています。お客様のビジネスへの新しいテクノロジーの導入にあたり、弊社のネイティブなコンサルティングとサポートを通じて、お客様の既存業務プロセスおよび新しいテクノロジーの可能性を見出すための支援を行っています。
        </p>
      </div>
    ),
    className: "mb-14",
  },
  {
    id: 3,
    firstSection: (
      <StaticImage
        quality={100}
        src="../../static/images/services/image4.png"
        alt="Services Image 4"
        objectFit="cover"
        width={470}
      />
    ),
    secondSection: (
      <div>
        <h3>ソフトウェアライブラリー</h3>
        <p className="leading-[22px]">
          全ての製品は各種カテゴリーに分類されており、デジタルインテグレーション、デジタルマーケティング、パフォーマス、セキュリティーなどの主要カテゴリーから、お客様のビジネスに適した製品を素早く簡単に見つけることができます。また、弊社のパートナーである海外の技術製品を調達するサービス支援もしています。
        </p>
      </div>
    ),
    isReversed: true,
    className: "mb-14",
  },
  {
    id: 4,
    firstSection: (
      <StaticImage
        quality={100}
        src="../../static/images/services/image5.png"
        alt="Services Image 5"
        objectFit="cover"
        width={470}
      />
    ),
    secondSection: (
      <div>
        <h3>経済的なメリット</h3>
        <p className="leading-[22px]">
          ワンストップサービスのサポートとともに、お客様のマーケティング予算に対して、最高のROIを得られるよう、トータルなサービス支援を提供します。現地通貨（円）でのお支払いが可能なため、外貨投資の心配をすることなく、弊社のサービスをご利用いただけます。
        </p>
      </div>
    ),
    className: "mb-[72px]",
  },
];

export default Service;

export const query = graphql`
  query MyQuery {
    bestSellingProducts: allStrapiProduct(
      filter: { status: { eq: "best_selling" } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        name
        slug
        Sections {
          ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
            subtitle
          }
        }
        logo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 126
                quality: 100
                width: 126
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
`;
